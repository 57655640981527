<template>
  <div>
    <v-container fluid>
      <div class="text-h4 my-5">EPS</div>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col class="title">
                  Notificações
                  <v-btn
                    @click="refreshNotification()"
                    icon
                    :disabled="loading"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field
                    v-model="search"
                    label="Pesquisar"
                    prepend-icon="mdi-magnify"
                    hide-details
                    @keydown.enter="refreshNotification()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end">
                  <ScopeProvider only-master>
                    <v-btn :to="{ name: 'new_notification' }" icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </ScopeProvider>
                </v-col>
              </v-row>
              <v-tabs v-model="tabs">
                <v-tab>Todas</v-tab>
                <v-tab>Fila</v-tab>
              </v-tabs>
              <v-divider class="mb-3"></v-divider>
              <v-tabs-items v-model="tabs">
                <v-tab-item>
                  <v-data-table
                    :loading="loading"
                    :items="notifications"
                    :headers="notification_headers"
                    :search="search"
                    :server-items-length="totalItems"
                    :options.sync="options"
                    dense
                  >
                    <template v-slot:item.lead_number="{ item }">
                      <router-link
                        class="link"
                        v-if="item.lead_number"
                        :to="`/leads/${item.lead_number}/integration`"
                      >
                        {{ item.lead_number }}
                      </router-link>
                      <span v-else class="grey--text">Nenhuma</span>
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-icon
                        color="green"
                        v-if="!item.inbound"
                        title="Recebimento"
                        small
                      >
                        mdi-arrow-down
                      </v-icon>
                      <v-icon small color="red" v-else title="Envio"
                        >mdi-arrow-up</v-icon
                      >
                      {{ item.action || "Nenhuma ação" }}
                    </template>
                    <template v-slot:item.executed_at="{ item }">
                      <span v-if="item.executed_at">
                        {{ formatDateTime(item.executed_at) }}
                      </span>
                      <span class="text--disabled" v-else> Não executado </span>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                      <span>
                        {{ formatDateTime(item.created_at) }}
                      </span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div>
                        <v-btn
                          :to="`/eps/notifications/${item.id}`"
                          text
                          color="info"
                          small
                        >
                          Editar
                        </v-btn>
                        <v-btn
                          @click="deleteNotification(item)"
                          :disabled="!!item.executed_at"
                          text
                          color="red"
                          small
                        >
                          Excluir
                        </v-btn>
                      </div>
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab-item>
                  <v-data-table
                    :loading="loading"
                    :items="notifications_queue"
                    :headers="notification_queue_headers"
                    :search="search"
                    :server-items-length="totalItemsQueued"
                    :options.sync="queue_options"
                    dense
                  >
                    <template v-slot:item.lead_number="{ item }">
                      <router-link
                        class="link"
                        v-if="item.lead_number"
                        :to="`/leads/${item.lead_number}/integration`"
                      >
                        {{ item.lead_number }}
                      </router-link>
                      <span v-else class="grey--text">Nenhuma</span>
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-icon
                        color="green"
                        v-if="!item.inbound"
                        title="Recebimento"
                        small
                      >
                        mdi-arrow-down
                      </v-icon>
                      <v-icon small color="red" v-else title="Envio"
                        >mdi-arrow-up</v-icon
                      >
                      {{ item.action || "Nenhuma ação" }}
                    </template>
                    <template v-slot:item.executed_at="{ item }">
                      <span v-if="item.executed_at">
                        {{ formatDateTime(item.executed_at) }}
                      </span>
                      <span class="text--disabled" v-else> Não executado </span>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-chip
                        label
                        small
                        outlined
                        color="green"
                        v-if="item.executed_at"
                        :title="'Executado às ' + item.executed_at"
                      >
                        Executado
                      </v-chip>
                      <v-chip
                        label
                        small
                        outlined
                        color="red"
                        v-else-if="item.blocked"
                      >
                        Falha
                      </v-chip>
                      <v-chip
                        label
                        small
                        outlined
                        color="grey"
                        v-else-if="item.ignored"
                      >
                        Aguardando
                      </v-chip>
                      <v-chip label small outlined color="info" v-else>
                        Processando
                      </v-chip>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                      <span>
                        {{ formatDateTime(item.created_at) }}
                      </span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div>
                        <v-btn
                          :to="`/eps/notifications/${item.id}`"
                          text
                          color="info"
                          small
                        >
                          Editar
                        </v-btn>
                        <v-btn
                          @click="deleteNotification(item)"
                          :disabled="!!item.executed_at"
                          text
                          color="red"
                          small
                        >
                          Excluir
                        </v-btn>
                      </div>
                    </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { formatDateTimeWithDuration } from "@/tools/date";
import ScopeProvider from "@/components/ScopeProvider";

export default {
  name: "eps",
  components: { ScopeProvider },
  data: () => ({
    search: "",
    loading: false,
    tabs: 0,
    totalItems: 0,
    totalItemsQueued: 0,
    options: {
      itemsPerPage: 15,
      sortBy: ["id"],
      sortDesc: ["id"],
    },
    queue_options: {
      itemsPerPage: 15,
      sortBy: ["created_at"],
      sortDesc: [false],
    },
    notifications: [],
    notifications_queue: [],
    notification_headers: [
      {
        text: "id",
        value: "id",
        width: "70",
      },
      {
        text: "Lead",
        value: "lead_number",
        width: "70",
      },
      {
        text: "Ação",
        value: "action",
        width: "250",
      },
      {
        text: "Executado em",
        value: "executed_at",
        width: "250",
      },
      {
        text: "Criada em",
        value: "created_at",
        width: "250",
      },
      {
        text: "Ações",
        value: "actions",
        width: "230",
      },
    ],
    notification_queue_headers: [
      {
        text: "id",
        value: "id",
        width: "70",
      },
      {
        text: "Lead",
        value: "lead_number",
        width: "70",
      },
      {
        text: "Ação",
        value: "action",
        width: "250",
      },
      {
        text: "Status",
        value: "status",
        width: "250",
        sortable: false,
      },
      {
        text: "Criada em",
        value: "created_at",
        width: "250",
      },
      {
        text: "Ações",
        value: "actions",
        width: "230",
        sortable: false,
      },
    ],
    poll: null,
  }),
  methods: {
    async deleteNotification(notification) {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: "Excluir notificação?",
        description: "A ação não poderá ser desfeita.",
        positive_action: async () => {
          try {
            const response = await this.$http.delete(
              "/leads/eps_notifications/" + notification.id
            );
            this.refreshNotification();
          } catch (error) {
            this.$store.commit("sendMessage", {
              text: error,
              color: "red",
            });
          }
        },
      });
    },
    refreshNotification() {
      if (this.tabs == 0) {
        return this.getNotifications();
      }

      if (this.tabs == 1) {
        return this.getNotificationQueue(this.queue_options);
      }
    },
    formatDateTime(raw_date) {
      return formatDateTimeWithDuration(raw_date);
    },
    async getNotifications() {
      this.notifications = [];
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.loading = true;
      const response = await this.$http.get("/eps/notifications", {
        params: {
          filter: this.search,
          sortBy: sortBy,
          sortDesc: sortDesc,
          page,
          itemsPerPage,
        },
      });

      const response_data = response.data;
      this.notifications = response_data.data;
      this.totalItems = response_data.total;

      this.loading = false;
    },
    async getNotificationQueue(options) {
      this.notifications_queue = [];
      const { sortBy, sortDesc, page, itemsPerPage } = options;

      this.loading = true;
      const response = await this.$http.get("/eps/notifications_queue", {
        params: {
          filter: this.search,
          sortBy,
          sortDesc,
          page,
          itemsPerPage,
        },
      });
      this.notifications_queue = response.data.data;
      this.totalItemsQueued = response.data.total;
      this.loading = false;
    },
  },
  watch: {
    options: {
      handler() {
        if (this.tabs == 0) {
          this.getNotifications();
        }
      },
      deep: true,
    },
    queue_options: {
      handler(value) {
        if (this.tabs == 1) {
          this.getNotificationQueue(value);
        }
      },
      deep: true,
    },
  },
  created() {
    this.getNotificationQueue(this.queue_options);
    this.getNotifications();
  },
};
</script>
